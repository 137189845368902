import { render, staticRenderFns } from "./SendTicketForm.vue?vue&type=template&id=dd7b0966&scoped=true"
import script from "./SendTicketForm.vue?vue&type=script&lang=ts"
export * from "./SendTicketForm.vue?vue&type=script&lang=ts"
import style0 from "./SendTicketForm.vue?vue&type=style&index=0&id=dd7b0966&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd7b0966",
  null
  
)

export default component.exports